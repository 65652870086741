import React from 'react'
import HomeLayout from '../layouts/Home/HomeLayout'
import '../bootstrap/css/bootstrap-grid.min.css'
import '../bootstrap/css/bootstrap.min.css'

class IndexPage extends React.Component{
  render(){
    return( <HomeLayout />)
  }
}


export default IndexPage
